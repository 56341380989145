import React, { useState, useEffect } from "react";
import "./cookieBanner.css";

const CookieBanner = () => {
    const [isBannerVisible, setIsBannerVisible] = useState(false);
    const [showPreferences, setShowPreferences] = useState(false);
    const [preferences, setPreferences] = useState({
        necessary: true,
        analytics: false,
        marketing: false,
    });

    useEffect(() => {
        const savedConsent = JSON.parse(localStorage.getItem("cookieConsent"));
        if (!savedConsent) {
            setIsBannerVisible(true);
        } else {
            setPreferences(savedConsent);
            applyConsent(savedConsent);
        }
    }, []);

    const handleAcceptAll = () => {
        const consent = {
            necessary: true,
            analytics: true,
            marketing: true,
        };
        setPreferences(consent);
        localStorage.setItem("cookieConsent", JSON.stringify(consent));
        applyConsent(consent);
        setIsBannerVisible(false);
    };

    const handleRejectAll = () => {
        const consent = {
            necessary: true,
            analytics: false,
            marketing: false,
        };
        setPreferences(consent);
        localStorage.setItem("cookieConsent", JSON.stringify(consent));
        applyConsent(consent);
        setIsBannerVisible(false);
    };

    const handleSavePreferences = () => {
        localStorage.setItem("cookieConsent", JSON.stringify(preferences));
        applyConsent(preferences);
        setIsBannerVisible(false);
    };

    const applyConsent = (consent) => {
        window.dataLayer = window.dataLayer || [];
        function gtag() {
            window.dataLayer.push(arguments);
        }
        gtag("consent", "update", {
            ad_storage: consent.marketing ? "granted" : "denied",
            analytics_storage: consent.analytics ? "granted" : "denied",
        });
    };

    const handlePreferenceChange = (e) => {
        const { name, checked } = e.target;
        setPreferences((prev) => ({ ...prev, [name]: checked }));
    };

    return (
        isBannerVisible && (
            <div className="cookieBanner__container">
                <div className="cookieBanner__content">
                    <div className="cookieBanner__text">
                        <h2>Wij gebruiken cookies</h2>
                        <p>
                            We kunnen deze plaatsen voor analyse van onze bezoekersgegevens, om onze website te verbeteren, gepersonaliseerde inhoud te tonen en u een geweldige website-ervaring te geven. Voor meer informatie over de cookies die we gebruiken, lees <a href="/Cookiebeleid">hier</a> meer.
                        </p>
                        {showPreferences && (
                            <div className="checkbox-container">
                                <div className="switch-wrapper">
                                    <span>Strikt noodzakelijke cookies</span>
                                    <label className="switch">
                                        <input type="checkbox" checked disabled />
                                        <span className="slider"></span>
                                    </label>
                                </div>
                                <div className="switch-wrapper">
                                    <span>Analytische cookies</span>
                                    <label className="switch">
                                        <input
                                            type="checkbox"
                                            name="analytics"
                                            checked={preferences.analytics}
                                            onChange={handlePreferenceChange}
                                        />
                                        <span className="slider"></span>
                                    </label>
                                </div>
                                <div className="switch-wrapper">
                                    <span>Marketingcookies</span>
                                    <label className="switch">
                                        <input
                                            type="checkbox"
                                            name="marketing"
                                            checked={preferences.marketing}
                                            onChange={handlePreferenceChange}
                                        />
                                        <span className="slider"></span>
                                    </label>
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="cookieBanner__buttons">
                        {!showPreferences ? (
                            <>
                                <button onClick={handleAcceptAll} className="cookie-button">
                                    Alles Accepteren
                                </button>
                                <button
                                    onClick={() => setShowPreferences(true)}
                                    className="cookie-button cookie-button-secondary"
                                >
                                    Instellingen Beheren
                                </button>
                            </>
                        ) : (
                            <>
                                <button onClick={handleAcceptAll} className="cookie-button">
                                    Alles Accepteren
                                </button>
                                <button onClick={handleSavePreferences} className="cookie-button">
                                    Keuzes Opslaan
                                </button>
                                <button
                                    onClick={handleRejectAll}
                                    className="cookie-button cookie-button-danger"
                                >
                                    Alles Weigeren
                                </button>
                            </>
                        )}
                    </div>
                </div>
            </div>
        )
    );    
};

export default CookieBanner;
