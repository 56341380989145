function removeUnnecessaryData(data, valueName) {
    const optimized = [];

    for (let i = 0; i < data.length; i++) {
        // Always keep the first and last elements
        if (i === 0 || i === data.length - 1) {
            optimized.push(data[i]);
            continue;
        }

        // Check if the previous, current, and next powerSetpoint_W values are the same
        if (
            data[i - 1][valueName] === data[i][valueName] &&
            data[i][valueName] === data[i + 1][valueName]
        ) {
            // Skip this point, as it's redundant
            continue;
        }

        // Otherwise, keep this point
        optimized.push(data[i]);
    }

    return optimized;
}

export default removeUnnecessaryData