import React, { useState } from 'react';
import '@fortawesome/fontawesome-free/css/all.min.css';
import './newsManager.css';
import NewsList from './newsList';
import AddNews from './addNews';

const NewsManager = () => {
    const [tab, setTab] = useState('newsList');
    const [newsToEdit, setNewsToEdit] = useState(null);

    const toggleTab = () => {
        setTab((prevTab) => (prevTab === 'newsList' ? 'addNews' : 'newsList'));
        setNewsToEdit(null);
    };

    const handleEditNews = (news) => {
        setNewsToEdit(news);
        setTab('addNews');
    };

    const getTitle = () => {
        if (tab === 'newsList') {
            return 'Beheer Nieuwsberichten';
        }
        return newsToEdit ? 'Bewerk Nieuwsbericht' : 'Voeg Nieuwsbericht Toe';
    };

    return (
        <div className="newsManager__body">
            <div
                className={`newsManager__header ${
                    tab === 'addNews' ? 'newsManager__header--narrow' : ''
                }`}
            >
                <h2 className="newsManager__title">{getTitle()}</h2>
                <button className="newsManager__add-button" onClick={toggleTab}>
                    {tab === 'newsList' ? 'Voeg een nieuw bericht toe' : 'Terug naar nieuwsberichten'}
                </button>
            </div>
            {tab === 'newsList' && <NewsList onEditNews={handleEditNews} />}
            {tab === 'addNews' && (
                <AddNews
                    existingNews={newsToEdit}
                    onSuccess={() => setTab('newsList')}
                />
            )}
        </div>
    );
};

export default NewsManager;
