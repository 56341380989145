import { useState, useEffect, useMemo, useRef } from 'react';
import FlowValues from '../../../api/flowValues';

const useFlowData = (serialNumber, retrykey, retryCount = 5, retryDelay = 5000) => {
    const flowApi = useMemo(() => FlowValues(), []);
    const [flowData, setFlowData] = useState(null);
    let interval = 120;

    useEffect(() => {
        if (serialNumber) {

            let isMounted = true;

            const fetchFlowData = async () => {
                setFlowData(null)
                let retriesLeft = retryCount;

                while (retriesLeft > 0) {
                    try {
                        if (!isMounted) return;

                        const data = await flowApi.getFlowValues(serialNumber, interval);

                        if (data && isMounted) {
                            setFlowData({
                                SolarW: data.pv_power.value,
                                GridW: data.grid_power.value,
                                BatteryW: data.battery_power.value,
                                HomeW: data.consumer_power.value,
                                BatteryP: Math.ceil(data.battery_soc.value * 100),
                            });
                            console.log("Flow data recived:", data);
                            
                            return; // Exit if successful
                        } else {
                            throw new Error("Empty data received");
                        }
                    } catch (error) {
                        retriesLeft--;
                        console.error(`Error fetching flow data: ${error.message}`);
                        interval += 30;
                        console.log(`Retries left: ${retriesLeft}. Retrying in ${retryDelay / 1000} seconds..., Increasing interval to ${interval}`);
                       
                        if (retriesLeft > 0) {
                            await new Promise(resolve => setTimeout(resolve, retryDelay));
                        }
                    }
                }

                // If all retries fail
                if (isMounted) {
                    console.error("Failed to fetch flow data after all retries.");
                    setFlowData("Error"); // Clear data on failure
                }
            };

            // Fetch immediately
            fetchFlowData()

            const intervalId = setInterval(fetchFlowData, 60000); // Polling every 60 seconds

            return () => {
                isMounted = false;
                clearInterval(intervalId);
            };
        }
    }, [serialNumber, retryCount, retryDelay, flowApi, retrykey]);

    return flowData;
};

export default useFlowData;