import Policy from "../../../api/policy";
import formatDataForChart from "./formatChartData";
import removeUnnecessaryData from "./removeUnnecessaryData";


const useSetpointData = async(serialNumber, globalDate, batteryInstallation) => {
    
    const policyApi = Policy();
    const amountOfInverters = batteryInstallation.batterys.length

    try {
        const raw_policys = await policyApi.getPolicys(serialNumber, globalDate);

        const createMidnightEntry = (date, powerSetpoint) => {
            const midnight = new Date(date);
            midnight.setHours(0,0,0,0);
            return {date: midnight, powerSetpoint_W: powerSetpoint}
        };

        const arr_policy = [];
        let previousPowerSetpoint = 0;

        for (let i = 0; i < raw_policys.policy.length; i++) {
            let date = new Date(raw_policys.time[i]);
            let powerSetpoint = raw_policys.powerSetpoint_W[i];

            //handle first iteration for midnight data if needed
            if (i === 0 && !(date.getHours() === 0 && date.getMinutes() === 0 && date.getSeconds() === 0)) {
                arr_policy.push(createMidnightEntry(raw_policys.time[i], 0));
            }

            //inserting previous data point
            const oneSecondBefore = new Date(date.getTime() - 1000);
            arr_policy.push({date: oneSecondBefore, powerSetpoint_W: previousPowerSetpoint});
            
            //adding current data point
            arr_policy.push({date: date, powerSetpoint_W: powerSetpoint});

            //update previous setpoint for the next iteration
            previousPowerSetpoint = powerSetpoint;

            //handle last iteration to add midnight 23:59:59 entry
            if (i === raw_policys.policy.length - 1) {
                arr_policy.push(createMidnightEntry(raw_policys.time[i], 0));
            }
        }

        let charge_setpointData = [];
        let discharge_setpointData = [];

        arr_policy.forEach((setpoint) => {
            if (setpoint.powerSetpoint_W >= 0) {
                discharge_setpointData.push({date: setpoint.date, discharge_setpoint: (setpoint.powerSetpoint_W / 1000) * amountOfInverters});
            }
            if (setpoint.powerSetpoint_W <= 0) {
                charge_setpointData.push({date: setpoint.date, charge_setpoint: Math.abs(setpoint.powerSetpoint_W / 1000) * amountOfInverters});
            }
        })

        charge_setpointData = formatDataForChart(removeUnnecessaryData(charge_setpointData, 'charge_setpoint'));
        discharge_setpointData = formatDataForChart(removeUnnecessaryData(discharge_setpointData, 'discharge_setpoint'));

        return { charge_setpointData, discharge_setpointData }

    } catch (error) {
        console.error("Error fetching policy setpoints", error);
        return { charge_setpointData: [], discharge_setpointData: [] }
    }
}

export default useSetpointData;