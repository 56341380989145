import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useNewsById, formatDate } from '../../../Utils/useNews';
import './newsDetailPage.css';

const NewsDetailPage = () => {
    const { id } = useParams();
    const { article, loading, error } = useNewsById(id);
    const [validImages, setValidImages] = useState([]);

    if (loading) {
        return <p>Artikel wordt geladen...</p>;
    }

    if (error) {
        return <p className="error-message">{error}</p>;
    }

    if (!article) {
        return <p>Artikel niet gevonden.</p>;
    }

    const images = [
        article.mainImage,
        ...(article.additionalImages || []),
    ].filter((image) => typeof image === 'string' && image.trim() !== '');

    const handleImageLoad = (image) => {
        setValidImages((prev) => [...prev, image]);
    };

    const handleImageError = (image) => {
        setValidImages((prev) => prev.filter((img) => img !== image));
    };

    return (
        <div className="newsDetailPage__body">
            <h1 className="newsDetailPage__title">{article.shortDescription}</h1>
            <p><strong>Datum:</strong> {formatDate(article.createdAt)}</p>
            <div className="newsDetailPage__content">
                <p
                    className="newsDetailPage__text"
                    dangerouslySetInnerHTML={{ __html: article.longDescription }}
                />

                <div className="newsDetailPage__images">
                    {validImages.length > 0 && (
                        validImages.length === 1 ? (
                            <img
                                src={validImages[0]}
                                alt="Main Image"
                                className="newsDetailPage__mainImage"
                            />
                        ) : (
                            <div className="newsDetailPage__additionalImages">
                                {validImages.map((image, index) => (
                                    <img
                                        key={index}
                                        src={image}
                                        alt={`Image ${index + 1}`}
                                        className="newsDetailPage__additionalImage"
                                    />
                                ))}
                            </div>
                        )
                    )}
                </div>

                <p className="newsDetailPage__hashtags">{article.hashtags}</p>
            </div>

            <div style={{ display: 'none' }}>
                {images.map((image, index) => (
                    <img
                        key={index}
                        src={image}
                        alt={`Hidden Check ${index + 1}`}
                        onLoad={() => handleImageLoad(image)}
                        onError={() => handleImageError(image)}
                    />
                ))}
            </div>
        </div>
    );
};

export default NewsDetailPage;
