import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import './dashboardSavings.css'

// import SavingsMeter from './SavingsMeter/savingsMeter'
import { DarkModeContext } from '../../../../context/DarkModeContext';
import { Area, CartesianGrid, ComposedChart, Legend, Line, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { addDays, addHours, format, isToday, startOfDay, subDays } from 'date-fns';
import loaderGIF from '../../../../Images/firn-embleem-animation.gif'
import useSOCData from '../../Dashboard-Hooks/useSOCData';
import useDayAheadData from '../../Dashboard-Hooks/useDayAheadData';
import useSetpointData from '../../Dashboard-Hooks/useSetpointData';
import useImbalanceData from '../../Dashboard-Hooks/useImbalanceData';

const DashboardSavings = ({serialNumber, batteryInstallation}) => {
    const {darkMode} = useContext(DarkModeContext);

    const [setpointsExist, setSetpointsExist] = useState(false);
    const [imbalanceExist, setImbalanceExist] = useState(false);
    const [maxBattCap, setMaxBattCap] = useState(0)
    const [globalDate, setDate] = useState(new Date())
    const [chartData, setChartData] = useState(null);
    const [dayAheadRange, setDayAheadRange] = useState({max: 0, min: 0});
    const [loading, setLoading] = useState(false);
    const [loadingError, setLoadingError] = useState(false);

    const [chartDataItem, setChartDataItem] = useState({
        soc: true,
        dayahead: true,
        charge_setpoint: true,
        discharge_setpoint: true,
        charge_imbalancesetpoint: true,
        discharge_imbalancesetpoint: true
    })

    const hourlyTicks = useMemo(() => {
        const start = startOfDay(new Date(globalDate));
        const ticks = Array.from({length: 24}, (_, i) => addHours(start, i).getTime());
        return [...new Set(ticks)];
    }, [globalDate])

    useEffect(() => {
        if (batteryInstallation) {
            setMaxBattCap(batteryInstallation.batterys.reduce((acc, battery) => acc + battery.capacity_kwh, 0));
        }
    }, [batteryInstallation])

    const fetchChartData = useCallback(async () => {
        try {
            setLoading(true)
            if (serialNumber && batteryInstallation) {
                

                const [socData, dayaheadData, { charge_setpointData, discharge_setpointData }, { charge_imbalanceData, discharge_imbalanceData}] = await Promise.all([
                    useSOCData(serialNumber, globalDate),
                    useDayAheadData(globalDate, setDayAheadRange),
                    useSetpointData(serialNumber, globalDate, batteryInstallation),
                    useImbalanceData(serialNumber, globalDate, batteryInstallation)
                ]);
                const newChartDataItem = { ...chartDataItem };

                if ((charge_setpointData && charge_setpointData.length !== 0) || (discharge_setpointData && discharge_setpointData.length !== 0)) {
                    setSetpointsExist(true);
                }
                if ((charge_imbalanceData && charge_imbalanceData.length !== 0) || (discharge_imbalanceData && discharge_imbalanceData.length !== 0)) {
                    setImbalanceExist(true);
                }

                setChartDataItem(newChartDataItem);

                const mergedData = mergeDataArrays(
                    socData || [],
                    dayaheadData || [],
                    charge_setpointData || [],
                    discharge_setpointData || [],
                    charge_imbalanceData || [],
                    discharge_imbalanceData || []
                );

                console.log(discharge_imbalanceData)

                setChartData(mergedData.sort((a, b) => a.timestamp - b.timestamp));
                console.log(mergedData.sort((a, b) => a.timestamp - b.timestamp))
            }
        } catch (error) {
            console.error("Error fetching data:", error);
            setLoadingError(true);
            setChartData(null);
        } finally {
            setLoading(false);
        }
    }, [serialNumber, batteryInstallation, globalDate]);

    useEffect(() => {
        fetchChartData();

        const interval = setInterval(() => {
            fetchChartData();
        }, 1000 * 60 * 15); // Fetch data every 15 minutes

        return () => clearInterval(interval);
    }, [fetchChartData]);

    const mergeDataArrays = (...dataArrays) => {
        const mergedDataMap = new Map();

        const mergeObjects = (obj1, obj2) => ({ ...obj1, ...obj2 });

        dataArrays.forEach(dataArray => {
            dataArray.forEach(item => {
                const { timestamp } = item;

                if (mergedDataMap.has(timestamp)) {
                    const existingEntry = mergedDataMap.get(timestamp);
                    mergedDataMap.set(timestamp, mergeObjects(existingEntry, item));
                } else {
                    mergedDataMap.set(timestamp, item);
                }
            });
        });

        return Array.from(mergedDataMap.values());
    }

    const handleLegendClick = (event) => {
        console.log(event)
        setChartDataItem((prevState) => ({
            ...prevState,
            [event.dataKey]: !prevState[event.dataKey]
        }));
    }

    const handleDateBackClick = () => setDate(subDays(globalDate, 1));
    
    const handleDateForwardClick = () => {
        if (!isToday(globalDate)) {
            setDate(addDays(globalDate, 1));
        }
    };

    const formatDate = (date) => {
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
    };

    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            const filteredPayload = payload.filter(
                (entry) => entry.value !== null && entry.value !== undefined && !isNaN(entry.value)
            );

            if (filteredPayload.length === 0) return null;

            const time = label ? new Date(label).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false }) : "N/A";

            const values = filteredPayload.map(entry => (
                <div key={entry.dataKey} style={{ color: entry.color }}>
                    {entry.name}: {entry.value.toFixed(2)}{entry.unit}
                </div>
            ));

            return (
                <div style={{
                    backgroundColor: 'rgba(255, 255, 255, 0.9)', padding: '10px', border: '1px solid #ddd', borderRadius: '10px',
                }}>
                    <div><strong>Time:</strong> {time}</div>
                    {values}
                </div>
            );
        }
        return null;
    };

    const ChartComponent = () => {
        return (
            <>
            <div style={darkMode ? {backgroundColor: '#353535', border: '2px solid #606060'} : {backgroundColor: '#D0D0D0', border: '2px solid #A0A0A0'}} className='GraphDisplay-Date-Date-Container'>
                <div className='GraphDisplay-Date-Changer' onClick={handleDateBackClick}>
                    <div style={darkMode ? {backgroundColor: '#D9D9D9'} : {backgroundColor: '#808080'}} className='GraphDisplay-Date-Changer-Bar Top Left'></div>
                    <div style={darkMode ? {backgroundColor: '#D9D9D9'} : {backgroundColor: '#808080'}} className='GraphDisplay-Date-Changer-Bar Bottom Left'></div>
                </div>
                <div>{formatDate(globalDate)}</div>
                <div className='GraphDisplay-Date-Changer' style={{ visibility: isToday(globalDate) ? 'hidden' : 'visible' }} onClick={handleDateForwardClick}>
                    <div style={darkMode ? {backgroundColor: '#D9D9D9'} : {backgroundColor: '#808080'}} className='GraphDisplay-Date-Changer-Bar Top Right'></div>
                    <div style={darkMode ? {backgroundColor: '#D9D9D9'} : {backgroundColor: '#808080'}} className='GraphDisplay-Date-Changer-Bar Bottom Right'></div>
                </div>
            </div>
            <ResponsiveContainer width="100%" height={400}>
                <ComposedChart
                    data={chartData}
                    margin={{ top: 30, right: 50, left: 10, bottom: 10 }}
                >
                    <defs>
                        <linearGradient id="socColor" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="5%" stopColor="#55D6C2" stopOpacity={0.8}/>
                            <stop offset="95%" stopColor="#55D6C2" stopOpacity={0}/>
                        </linearGradient>
                        <linearGradient id="policysPos" x1="0" y1="1" x2="0" y2="0">
                            <stop offset="0%" stopColor="#4285F4" stopOpacity={1}/>
                            <stop offset="100%" stopColor="#5A9DFB" stopOpacity={1}/>
                        </linearGradient>
                        <linearGradient id="policysNeg" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="0%" stopColor="#FFA040" stopOpacity={0.5}/>
                            <stop offset="100%" stopColor="#FFB74D" stopOpacity={1}/>
                        </linearGradient>
                        <linearGradient id="chargeImbalance" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="100%" stopColor="#2f7332" stopOpacity={1}/>
                            <stop offset="100%" stopColor="#43A047" stopOpacity={1}/>
                        </linearGradient>
                        <linearGradient id="dischargeImbalance" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="0%" stopColor="#D32F2F" stopOpacity={1}/>
                            <stop offset="100%" stopColor="#871e1e" stopOpacity={1}/>
                        </linearGradient>
                    </defs>

                    {darkMode ? <CartesianGrid key={'griddarkmode'} style={{opacity: 0.30}} strokeDasharray="10 10"/> : <CartesianGrid key={'gridlightmode'} strokeDasharray="3 3" />}

                    <XAxis 
                        key='timestamp'
                        dataKey="timestamp" 
                        type="number" 
                        domain={['dataMin', 'dataMax']} 
                        ticks={hourlyTicks} 
                        tickFormatter={(tick) => format(new Date(tick), 'HH:mm')} 
                        interval={4}
                    />

                    <Tooltip content={<CustomTooltip />} />
                    <Legend onClick={handleLegendClick} wrapperStyle={{ color: '#555', fontSize: '0.9rem', fontWeight: 'bold', cursor: 'pointer' }}/>

                    {dayAheadRange.min < 0 ? <>
                        <YAxis key={'socAxisnegative'} hide={setpointsExist} yAxisId={"socAxis"} domain={[-100, 100]} tickFormatter={(tick) => tick >= 0 ? `${tick}%` : ''}/>
                        <YAxis key={'dayaheadAxisnegative'} orientation='right' unit={'€/MWh'} yAxisId={"dayaheadAxis"} ticks={[-dayAheadRange.max, -dayAheadRange.max/2, 0, dayAheadRange.max/2, dayAheadRange.max]}/>
                        <YAxis key={'policyAxisnegative'} yAxisId={"policyAxis"} hide={!setpointsExist} domain={[-maxBattCap, maxBattCap]} unit={'kW'} />
                    </> : <>
                        <YAxis key={'socAxis'} hide={setpointsExist} yAxisId={"socAxis"} domain={[0, 100]} tickFormatter={(tick) => tick >= 0 ? `${tick}%` : ''}/>
                        <YAxis key={'dayaheadAxis'} orientation='right' unit={'€/MWh'} hide={false} yAxisId={"dayaheadAxis"} ticks={[0, Math.ceil(dayAheadRange.max/4), Math.ceil(dayAheadRange.max/2), Math.ceil(dayAheadRange.max/4 * 3), dayAheadRange.max]}/>
                        <YAxis key={'policyAxis'} yAxisId={"policyAxis"} hide={!setpointsExist} ticks={[0, Math.floor(maxBattCap/4), Math.floor(maxBattCap/2), Math.floor(maxBattCap/4*3), maxBattCap]} unit={'kW'} />
                    </>}
                    
                    {imbalanceExist && 
                        <>
                            <Area unit={'kW'} yAxisId={'policyAxis'} hide={!chartDataItem.charge_imbalancesetpoint} legendType='circle' type={'stepAfter'} connectNulls={true} name='Imbalance (charge)' dataKey="charge_imbalancesetpoint" strokeWidth={0} stroke="#43A047" fill='url(#chargeImbalance)' dot={false}/>
                            <Area unit={'kW'} yAxisId={'policyAxis'} hide={!chartDataItem.discharge_imbalancesetpoint} legendType='circle' type={'stepAfter'} connectNulls={true} name='Imbalance (discharge)' dataKey="discharge_imbalancesetpoint" strokeWidth={0} stroke="#D32F2F" fill='url(#dischargeImbalance)' dot={false}/>
                        </>
                    }
                    {setpointsExist && (
                        <>
                            <Area unit={'kW'} yAxisId={"policyAxis"} hide={!chartDataItem.charge_setpoint} legendType='circle' type="monotone" connectNulls={true} name='Dayahead (charge)' dataKey="charge_setpoint" fill='url(#policysPos)' strokeWidth={0} stroke="#5A9DFB" dot={false} />
                            <Area unit={'kW'} yAxisId={"policyAxis"} hide={!chartDataItem.discharge_setpoint} legendType='circle' type="monotone" connectNulls={true} name='Dayahead (discharge)' dataKey="discharge_setpoint" fill='url(#policysNeg)' strokeWidth={0} stroke="#FFA040" dot={false} />
                        </>
                    )}

                    <Line unit={setpointsExist ? 'kWh' : '%'} yAxisId={setpointsExist ? "policyAxis" : "socAxis"} legendType='plainline' type="monotone" name='State of charge' hide={!chartDataItem.soc} connectNulls={true} dataKey={(data) => setpointsExist ? ((data.soc / 100) * maxBattCap) : data.soc} strokeWidth={2} stroke="#55D6C2" dot={false} />
                    <Line unit={'€/MWh'} yAxisId={"dayaheadAxis"} hide={!chartDataItem.dayahead} legendType='plainline' type="monotone" name='Day-ahead pricing' connectNulls={true} dataKey="dayahead" strokeWidth={2} stroke="#D79928" dot={false} />
                </ComposedChart>
            </ResponsiveContainer>
            </>
        );
    };

    const handleRetry = () => {
        setLoadingError(false);
        fetchChartData(); // Retry fetching the data
    };

    if (loading) {
        return (
            <div className="dashboard__savings__container" style={{justifyContent: 'center', alignItems: 'center'}}>
                <img style={{ margin: 'auto' }} height={150} width={150} src={loaderGIF} alt="Loading..." />
            </div>
        );
    }

    if (loadingError) {
        console.log("loading error returned")
        return (
          <div className='dashboard__savings__container' style={{justifyContent: 'center', alignItems: 'center'}}>
            <div>Error loading data. Please try again later.</div>
            <button onClick={handleRetry} className='dashboard__savings__retry__button'>Retry</button>
          </div>
        );
    }
    
    return (
        <div className="dashboard__savings__container">
            <ChartComponent />
        </div>
    );
};

export default DashboardSavings;



{/* <div className='dashboard__savings__imbalance'>Imbalance</div> */}
            {/* <ChartComponent /> */}
            
            {/* Uncomment and adjust these sections as needed */}
            {/* <div className={`dashboard__savings__underdevelopment__container__${darkMode ? 'darkmode' : 'lightmode'}`}>
                <div className={`dashboard__savings__underdevelopment__message__${darkMode ? 'darkmode' : 'lightmode'}`}>
                    This section is currently under development and will be available soon.
                </div>
            </div> */}
            {/* <div className='dashboard__savings__header'>Savings</div>
            <div className='dashboard__savings__content'>
                <div className='dashboard__savings__overdrive__container'>
                    <div className='dashboard__savings__overdrive__header'>Yesterday</div>
                    <div className='dashboard__savings__overdrive__meter__container'>
                        <SavingsMeter darkMode={darkMode} price={yesterDaySavings}/>
                    </div>
                    <div className='dashboard__savings__overdrive__value'>{yesterDaySavings}€</div>
                </div>
                <div className='dashboard__savings__overdrive__container'>
                    <div className='dashboard__savings__overdrive__header'>Today</div>
                    <div className='dashboard__savings__overdrive__meter__container'>
                        <SavingsMeter darkMode={darkMode} price={todaySavings}/>
                    </div>
                    <div className='dashboard__savings__overdrive__value'>{todaySavings}€</div>
                </div>
            </div> */}