import React from "react";
import "./cookiebeleid.css";

const Cookiebeleid = () => {
    return (
        <div className="cookiebeleid__body">
            <header className="cookiebeleid__header">
                {/* ================================================================
                STEEDS DE DATUM AANPASSEN ALS JE AANPASSINGEN MAAKT AAN DEZE PAGINA!
                ==================================================================== */}
                <p className="cookiebeleid__last-updated"><strong>Laatst bijgewerkt:</strong> 23 januari 2025</p>
                <h1>Privacy- en Cookiebeleid</h1>
            </header>

            <section className="cookiebeleid__section">
                <p>Deze Privacyverklaring beschrijft hoe FIRN Energy jouw persoonsgegevens verwerkt. FIRN Energy is een vennootschap naar Belgisch recht, gevestigd op Parkstraat 23, 8930 Menen.</p>
                <p>Bij FIRN Energy vinden we jouw privacy van groot belang. We behandelen jouw persoonsgegevens altijd op een wettelijke, transparante en correcte manier. Onze verwerking voldoet volledig aan de Algemene Verordening Gegevensbescherming (AVG) en andere relevante wetgeving.</p>
                <p>In deze Privacyverklaring leggen we helder uit hoe we jouw gegevens verzamelen, gebruiken en beschermen. Ook vind je hier meer informatie over de cookies die we gebruiken. Door gebruik te maken van onze website, contact met ons op te nemen via e-mail of telefoon, of een vraag of klacht in te dienen, ga je akkoord met deze Privacyverklaring.</p>
                <p>FIRN Energy zet zich in om jouw rechten te respecteren en jouw persoonsgegevens veilig te beheren.</p>
            </section>

            <section className="cookiebeleid__section">
                <h2>Welke gegevens verzamelen wij?</h2>
                <p>We verzamelen gegevens via:</p>
                <ul>
                    <li>Formulieren op de website (zoals naam en e-mailadres).</li>
                    <li>Cookies en trackingtechnologieën (zie hieronder voor details).</li>
                </ul>
            </section>

            <section className="cookiebeleid__section">
                <h2>Waarom verzamelen wij jouw gegevens?</h2>
                <p>We gebruiken jouw gegevens voor:</p>
                <ul>
                    <li>Het leveren van onze diensten en producten.</li>
                    <li>Het analyseren en verbeteren van onze website.</li>
                    <li>Het beantwoorden van je vragen en verzoeken.</li>
                </ul>
            </section>

            <section className="cookiebeleid__section">
                <h2>Cookies</h2>
                <p>Onze website gebruikt cookies. Hieronder volgt een overzicht:</p>
                <table className="cookie-table">
                    <thead>
                        <tr>
                            <th>Naam</th>
                            <th>Doel</th>
                            <th>Bewaarperiode</th>
                            <th>Provider</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>darkmode</td>
                            <td>Opslaan van voorkeur voor donkere modus</td>
                            <td>1 jaar</td>
                            <td>www.firnenergy.com</td>
                        </tr>
                        <tr>
                            <td>_ga</td>
                            <td>Tracking van gebruikersstatistieken</td>
                            <td>2 jaar</td>
                            <td>Google Analytics</td>
                        </tr>
                        <tr>
                            <td>_ga_#</td>
                            <td>Tracking van gebruikersgedrag</td>
                            <td>2 jaar</td>
                            <td>Google Analytics</td>
                        </tr>
                    </tbody>
                </table>
            </section>

            <section className="cookiebeleid__section">
                <h2>Jouw rechten</h2>
                <p>Je hebt recht op:</p>
                <ul>
                    <li>Inzage in je gegevens.</li>
                    <li>Correctie van foutieve gegevens.</li>
                    <li>Verwijdering van je gegevens (indien mogelijk binnen wettelijke kaders).</li>
                    <li>Bezwaar maken tegen gebruik van je gegevens.</li>
                </ul>
                <p>
                    Stuur een verzoek naar <a href="mailto:sales@firnenergy.com">sales@firnenergy.com</a>. We reageren binnen 30 dagen.
                </p>
            </section>

            <section className="cookiebeleid__section">
                <h2>Hoe delen wij jouw gegevens?</h2>
                <p>Wij verkopen jouw gegevens nooit aan derden. Wel delen wij gegevens indien dit noodzakelijk is, bijvoorbeeld met:</p>
                <ul>
                    <li>Leveranciers of partners die ons ondersteuenen in onze dienstverlening.</li>
                    <li>Wettelijke instanties indien vereist.</li>
                </ul>
                <p>Met alle externe partijen waarmee wij samenwerken, sluiten wij verwerkersovereenkomsten af om jouw gegevens te beschermen.</p>
            </section>

            <section className="cookiebeleid__section">
                <h2>Beveiliging van jouw gegevens</h2>
                <p>We nemen passende maatregelen om je gegevens te beschermen tegen verlies, misbruik of ongeoorloofde toegang.</p>
            </section>

            <section className="cookiebeleid__section">
                <h2>Wijzigingen in dit beleid</h2>
                <p>We kunnen dit beleid bijwerken. Controleer regelmatig deze pagina voor updates.</p>
            </section>

            <footer className="cookiebeleid__footer">
                <p>
                    Heb je vragen? Neem contact met ons op via <a href="mailto:sales@firnenergy.com">sales@firnenergy.com</a>.
                </p>
            </footer>
        </div>
    );
};

export default Cookiebeleid;
