import { useContext, useEffect, useState } from 'react';
import { DarkModeContext } from '../../../../context/DarkModeContext';
import loaderGIF from '../../../../Images/firn-embleem-animation.gif'

import './batteryStats.css'
import Settings from '../../../../api/settings';

const BatteryStats = ({serialNumber, batteryPercentage, batteryStatus, updateBatteryInstallation}) => {

    const [installationDetails, setInstallationDetails] = useState(null)
    const [groupedBatteries, setGroupedBatteries] = useState(null)
    const [groupedInverters, setGroupedInverters] = useState(null)

    const {darkMode} = useContext(DarkModeContext);

    const settingsApi = Settings();

    const groupBy = (items, key) => {
        return items.reduce((acc, item) => {
            acc[item[key]] = (acc[item[key]] || 0) + 1;
            return acc;
        }, {});
    }

    const retrieveInstallationDetails = async() => {
        try {
            const { power_array } = await settingsApi.getClientDetails(serialNumber);
            const batterys = [];
            const inverters = [];

            Object.values(power_array).forEach(({ battery, inverter }) => {

                if (battery) {
                    batterys.push(battery);
                    inverters.push(inverter);
                } 
            });

            setGroupedInverters(groupBy(inverters, "power_rating_kw"));
            setGroupedBatteries(groupBy(batterys, "capacity_kwh"));
            setInstallationDetails({ batterys, inverters });
            updateBatteryInstallation({ batterys, inverters })
        } catch (err) {
            console.log("Error while fetching client details");
            setInstallationDetails(null);
        }
    }

    useEffect(() => {
        if (serialNumber) {
            retrieveInstallationDetails();
        }
    }, [serialNumber])

    const batteryColors = [
        '#D83535',
        '#CD4935',
        '#C15E35',
        '#B67135',
        '#AB8635',
        '#A69135',
        '#A09B35',
        '#95AE35',
        '#89C435',
        '#00B26C' 
    ];

    const getBatteryColor = (percentage) => {
        const colors = [ '#D83535', '#CD4935', '#C15E35', '#B67135', '#AB8635', '#A69135', '#A09B35', '#95AE35', '#89C435', '#00B26C' ];
        return colors[Math.min(Math.ceil(percentage / 10) - 1, colors.length -1)];
    }

    const renderDetails = (groupedData, unit) =>
        Object.entries(groupedData)
            .map(([key, count]) => `${count}x${key}${unit}`)
            .join(" / ");

    const CapacityDetails = ({ title, details, unit}) => (
        <div>
            <div>{title}:</div>
            <div className='Dashboard__BatteryStats__Details__List'>
                {details}
            </div>
        </div>
    )

    const currentCharge = Math.ceil(installationDetails?.batterys?.reduce((sum, battery) => sum + battery.capacity_kwh, 0) * (batteryPercentage / 100)) || 0;

    if (!batteryPercentage) {
        return <img style={{margin: 'auto'}} height={150} width={150} className='loading-gif' src={loaderGIF} alt="" />
    }

    return (
        <>
            <div className='Dashboard-Body-Overview-Row-Element-Object-Container'>
                <div className='Dashboard-Body-Overview-Row-Element-Battery-Container'>
                    
                    <div className='Dashboard-Body-Overview-Row-Element-Battery-Object-Bottom' style={{border: darkMode ?  'white solid 4px' : 'gray solid 4px'}}>
                        <div className='dashboard__body__overview__row__element__battery__object__filler' style={{width: `${batteryPercentage}%`, backgroundColor: getBatteryColor(batteryPercentage)}}></div>
                        {batteryStatus == "Charging" && <>
                            <div className='dashboard__body__overview__row__element__battery__object__filler_arrow_animation' style={{left: `${batteryPercentage - 10}%`}}></div>
                            <div className='dashboard__body__overview__row__element__battery__object__filler_arrow_animation1' style={{left: `calc(${batteryPercentage - 10}% + 10px)`}}></div>
                        </>}
                        {batteryStatus == "Discharging" && <>
                            <div className='dashboard__body__overview__row__element__battery__object__filler_arrow_animationred' style={{left: `${batteryPercentage - 12.5}%`}}></div>
                            <div className='dashboard__body__overview__row__element__battery__object__filler_arrow_animation1red' style={{left: `calc(${batteryPercentage - 12.5}% + 10px)`}}></div>
                        </>}
                        </div>
                    <div className='Dashboard-Body-Overview-Row-Element-Battery-Object-Top' style={{border: darkMode ? 'white solid 4px' : 'gray solid 4px'}}></div>
                </div>
                
                <div className='Dashboard-Body-Overview-Row-Element-Battery-Container Percentage'>
                    <div className='Dashboard-Body-Overview-Row-Element-Battery-Object-Bottom Percentage'>
                        <div style={{width: `${batteryPercentage - 10}%`}} className='Dashboard-Body-Overview-Row-Element-Battery-Object-Bottom-Indicator-Pushback'></div>
                        <div className='Dashboard-Body-Overview-Row-Element-Battery-Object-Bottom-Indicator-Container'>
                            <div className='Dashboard-Body-Overview-Row-Element-Battery-Object-Bottom-Indicator' style={{backgroundColor: darkMode ? 'white' : 'gray'}}></div>
                            <div>{`${batteryPercentage}%`}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='Dashboard-Body-Overview-Row-Element-Battery-Data-Container'>
                <div className='Dashboard-Body-Overview-Row-Element-Battery-Data-Graph-Container'>
                    {installationDetails && <>
                        <CapacityDetails 
                            title="Battery capacity"
                            details={renderDetails(groupedBatteries, 'KWh')}
                        />
                        <CapacityDetails
                            title="Inverter capacity"
                            details={renderDetails(groupedInverters, "KW")}
                        />
                        <div>
                            <div>Current charge:</div>
                            <div style={{margin: '5px 0px 5px 20px', opacity: 0.50}}>{currentCharge}KWh</div>
                        </div>
                        <div>
                            <div>Current state:</div>
                            <div style={{margin: '5px 0px 5px 20px', opacity: 0.50}}>{batteryStatus}</div>
                        </div>
                    </>
                    }
                </div>
            </div>
        </>
    )
}

export default BatteryStats;



{/* <div className='dashboard__overview__Battery__box__graph__titles'>
    {items.map((item, i) => (
        <div key={i} className={`dashboard__overview__Battery__box__graph__titles__item${i === activeItem ? '--active' : ''}`} onClick={() => setActiveItem(i)}>{item.title}</div>
    ))}
    <div style={darkMode ? {backgroundColor: '#353535', border: '2px solid #606060'} : {backgroundColor: '#D0D0D0', border: '2px solid #A0A0A0'}} className='GraphDisplay-Date-Date-Container'>
        <div className='GraphDisplay-Date-Changer' onClick={handleDateBackClick}>
            <div style={darkMode ? {backgroundColor: '#D9D9D9'} : {backgroundColor: '#808080'}} className='GraphDisplay-Date-Changer-Bar Top Left'></div>
            <div style={darkMode ? {backgroundColor: '#D9D9D9'} : {backgroundColor: '#808080'}} className='GraphDisplay-Date-Changer-Bar Bottom Left'></div>
        </div>
        <div>{formatDate(date)}</div>
        <div className='GraphDisplay-Date-Changer' style={{ visibility: isToday(date) ? 'hidden' : 'visible' }} onClick={handleDateForwardClick}>
            <div style={darkMode ? {backgroundColor: '#D9D9D9'} : {backgroundColor: '#808080'}} className='GraphDisplay-Date-Changer-Bar Top Right'></div>
            <div style={darkMode ? {backgroundColor: '#D9D9D9'} : {backgroundColor: '#808080'}} className='GraphDisplay-Date-Changer-Bar Bottom Right'></div>
        </div>
    </div>
</div> */}
{/* <div className='Dashboard-Body-Overview-Row-Element-Battery-Data-Graph'>
    {activeItem === 0 && <BatteryChart triggerDataRetrieval={triggerDataRetrieval} TriggerActivity={TriggerActivity} serialNr={serialNr}/>}
</div> */}

// const formatDate = (date) => {
//     const day = String(date.getDate()).padStart(2, '0');
//     const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
//     const year = date.getFullYear();
    
//     return `${day}-${month}-${year}`;
// }

// const handleDateBackClick = () => {
//     setDate(subDays(date, 1))
//     setTriggerDataRetrieval(subDays(date, 1))
// }

// const handleDateForwardClick = () => {
//     if (!isToday(date)) {
//         setDate(addDays(date, 1));
//         setTriggerDataRetrieval(addDays(date, 1))
//     }
    
// }