import GraphValues from "../../../api/graphValues";
import formatDataForChart from "./formatChartData";

const useSOCData = async (serialNumber, globalDate) => {
    
    const graphValuesApi = GraphValues();
    
    try {
        const { battery_soc: soc } = await graphValuesApi.getGraphData(serialNumber, globalDate, "5min");

        if (!soc || soc.length === 0) throw new Error("Data is empty");
        const renewedSOC = []
        soc.values.map((charge, i) => {
            if (charge) {
                renewedSOC.push({
                    date: new Date(soc.time[i]),
                    soc: Math.ceil(charge * 100)
                })
            }
        });

        return formatDataForChart(renewedSOC);
    } catch (error) {
        console.error("Error fetching SOC data:", error);
        return null;
    }
}

export default useSOCData;