import Policy from "../../../api/policy";
import formatDataForChart from "./formatChartData";

const useImbalanceData = async(serialNumber, globalDate, batteryInstallation) => {

    const policyApi = Policy()
    const amountOfInverters = batteryInstallation.batterys.length

    try {
        const data = await policyApi.getImbalance(serialNumber, globalDate);

        //Split imbalance data into 2 charts (one positive & one negative)

        let imbalanceData = []
        //variable to hold info about who the next 0 belongs to (-1 belongs to discharge, 0 stand-by, 1 belongs to charge)
        let nextZero = 0

        let charge_imbalanceData = []
        let discharge_imbalanceData = []
        for (let index = 0; index < data.powerSetpoint_W.length; index++) {
            let value = data.powerSetpoint_W[index] * amountOfInverters;
            let time = data.time[index];

            if (value > 0) {
                if (nextZero == 1) {
                    charge_imbalanceData.push({date: new Date(time), charge_imbalancesetpoint: 0})
                }
                discharge_imbalanceData.push({date: new Date(time), discharge_imbalancesetpoint: Math.abs(value/1000)})
                nextZero = -1
                //discharge setpoint
            } else if (value < 0) {

                if (nextZero == -1) {
                    discharge_imbalanceData.push({date: new Date(time), discharge_imbalancesetpoint: 0})
                }
                charge_imbalanceData.push({date: new Date(time), charge_imbalancesetpoint: Math.abs(value/1000)})
                nextZero = 1
                //charge sepoint
            } else {
                if (nextZero == 1) {
                    charge_imbalanceData.push({date: new Date(time), charge_imbalancesetpoint: Math.abs(value/1000)})
                } else if (nextZero == -1) {
                    discharge_imbalanceData.push({date: new Date(time), discharge_imbalancesetpoint: Math.abs(value/1000)})
                }
                nextZero = 0
            }
        }

        charge_imbalanceData = formatDataForChart(charge_imbalanceData);
        discharge_imbalanceData = formatDataForChart(discharge_imbalanceData);


        return { charge_imbalanceData, discharge_imbalanceData }
        //new code
        const createMidnightEntry = (date, powerSetpoint) => {
            const midnight = new Date(date);
            midnight.setHours(0,0,0,0);
            return {date: midnight, imbalance: powerSetpoint}
        };

        const arr_policy = [];
        let previousPowerSetpoint = 0;

        for (let i = 0; i < imbalanceData.length; i++) {
            let date = new Date(imbalanceData[i].date);
            let powerSetpoint = -imbalanceData[i].imbalance;

            //handle first iteration for midnight data if needed
            if (i === 0 && !(date.getHours() === 0 && date.getMinutes() === 0 && date.getSeconds() === 0)) {
                arr_policy.push(createMidnightEntry(imbalanceData[i].date, 0));
            }

            //inserting previous data point
            const oneSecondBefore = new Date(date.getTime() - 1000);
            arr_policy.push({date: oneSecondBefore, imbalance: previousPowerSetpoint});
            
            //adding current data point
            arr_policy.push({date: date, imbalance: powerSetpoint});

            //update previous setpoint for the next iteration
            previousPowerSetpoint = powerSetpoint;

            //handle last iteration to add midnight 23:59:59 entry
            if (i === imbalanceData.length - 1) {
                arr_policy.push(createMidnightEntry(imbalanceData[i].date, 0));
            }
        }

        imbalanceData = arr_policy

        if (imbalanceData) {
            console.log(imbalanceData)
        }
        

        return (formatDataForChart(imbalanceData))

        // if (!data || !data.element || data.element != "yuso" || data.powerSetpoint_W.length < 1) {
        //     throw new Error("no correct data");
        // }

        // const dataReformat = []
        // for (let i = 0; i < data.powerSetpoint_W.length; i++) {
        //     const dateObject = new Date(data.time[i])
        //     dataReformat.push({"date": dateObject, "value": data.powerSetpoint_W[i] == 0 ? 0 : data.powerSetpoint_W[i] > 0 ? 1000 : -1000})
        // }

        // const newReformat = []
        // for (let i = 0; i < dataReformat.length; i++) {
        //     if (dataReformat[i].value != 0) {
        //         newReformat.push({date: dataReformat[i].date, value: dataReformat[i].value})

        //         let j = i + 1

        //         while (true) {
        //             if (j < dataReformat.length) {
        //                 if (dataReformat[j].value === dataReformat[i].value) {
        //                     //keep going
        //                     j++
        //                 } else {
        //                     newReformat.push({date: dataReformat[j - 1].date, value: dataReformat[j - 1].value})
        //                     newReformat.push({date: dataReformat[j].date, value: dataReformat[j].value})

        //                     i = j
        //                     break;
        //                 }
        //             } else {
        //                 newReformat.push({date: dataReformat[j - 1].date, value: dataReformat[i].value})
        //                 break;
        //             }
        //         }
        //     } else {
        //         newReformat.push({date: dataReformat[i].date, value: 0})
        //     }
        // }

        // let formattedData = formatDataForChart(newReformat)

        // return formattedData
    } catch (error) {
        console.log(error)
        return ([])
        //trigger parent event
    }
}

export default useImbalanceData;