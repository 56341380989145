import { useAuth0 } from '@auth0/auth0-react';
import { useLocation, useNavigate } from 'react-router-dom';
import User from '../../api/user';
import axios from 'axios';
import { useContext, useEffect, useState } from 'react'
import DashboardNavbar from './Dashboard-Navbar/dashboardNavbar';
import DashboardSideNavbar from './Dashboard-Navbar/dashboardSideNavbar'
import DashboardOverview from './Dashboard-Overview/dashboardOverview'
import DashboardCalendar from './Dashboard-Calendar/dashboardCalendar'
import DashbordSettings from './Dashboard-Settings/dashbordSettings';
import './dashboard.css'
import Measurement from '../../api/measurements';
import loaderGIF from '../../Images/firn-embleem-animation.gif'
import { DarkModeContext } from '../../context/DarkModeContext';
import { UserContext } from '../../context/UserContext';


const Dashboard = () => {
    
    const {darkMode} = useContext(DarkModeContext);
    const { userMock, login } = useContext(UserContext);
    const [userMockObject, setUserMockObject] = useState(userMock);

    const navigate = useNavigate(); //defines the navigator
    const { user, isAuthenticated, isLoading } = useAuth0(); //gets user data from auth0

    const [serialNr, setSerialNr] = useState("");
    const [loading, setLoading] = useState(true); //Loader to indictate a process is being executed
    const [activeTab, setActiveTab] = useState("Dashboard")
    const [accountType, setAccountType] = useState("");
    const [changesMade, setChangesMade] = useState(false)
    const [changesMadewithoutSaving, setChangesMadewithoutSaving] = useState(false)


    const [isAdmin, setIsAdmin] = useState(false)
    //Checking authentication state (Admin => Admin Page, None => HomePage)
    useEffect(() => {
        const fetchData = async () => {
            if (isLoading || !user) return;

            if (isAuthenticated) {
                const roles = user['https://firnenergy.com/roles'];

                if (roles?.includes("SuperAdmin") || roles?.includes("Admin") || roles?.includes("Installer") || roles?.includes("Sales")) {
                    if (userMock) {
                        console.log("Admin with serial:", userMock.app_metadata.serial_Nr);
                        setSerialNr(userMock.app_metadata.serial_Nr)
                        setAccountType(userMock.app_metadata.type);
                        setIsAdmin(true)
                    } else {
                        console.log("userMock is null; redirecting to AdminPage");
                        navigate("/AdminPage")
                    }
                } else if (roles?.includes("User")) {
                    await handleUserRole();
                } else {
                    navigate("/");
                }
            } else if (!isAuthenticated) {
                navigate("/");
            }
        };

        fetchData()
    }, [isAuthenticated, isLoading, user]);

    const handleUserRole = async () => {
        const userApi = User();

        try {
            const ManagementToken = await userApi.getManagementApiToken();
            const options = {
                method: 'GET',
                url: `https://${process.env.REACT_APP_AUTH0_DOMAIN}/api/v2/users`,
                params: { q: `email:${user.email}`, search_engine: 'v3' },
                headers: { authorization: `Bearer ${ManagementToken}` },
            };
    
            const response = await axios.request(options);
            const temp_metadata = response.data[0]?.app_metadata;
    
            login(response.data[0]); // Add user to context
            if (temp_metadata) {
                setSerialNr(temp_metadata.serial_Nr);
                setAccountType(temp_metadata.type || "Residentieel");
            }
        } catch (error) {
            console.error("Failed to fetch user metadata:", error);
        }
    }

    useEffect(() => {
        if (userMock != null) {
            setUserMockObject(userMock)
        }
    }, [userMock])

    const changeTab = (tab) => {
        //check if changes have been made
        if (changesMade) {
            setChangesMadewithoutSaving(true)
            return false
        }
        setActiveTab(tab);
        return true;
    }

    return (
        <>
            <div className='Dashboard-Container'>
                {/* {loading && (
                    <div className='overlay block-interactions'>
                        <div className="loading-container">
                            <img className='loading-gif' src={loaderGIF} alt="" />
                        </div>
                    </div>
                )} */}
                {changesMadewithoutSaving && (
                    <div className='overlay block-interactions'>
                        <div className="changesMadeWithoutSaving-Container">
                            <div>Are you sure you want to go without saving?</div>
                            <div style={{display: 'flex', flexFlow: 'row nowrap', justifyContent: 'space-evenly'}}>
                                <button className='neutral-button' onClick={() => {
                                    setChangesMade(false);
                                    setChangesMadewithoutSaving(false)
                                }}>continue without saving</button>
                                <button className='neutral-button' onClick={() => {
                                    setChangesMadewithoutSaving(false)
                                }}>cancel</button>
                            </div>
                        </div>
                    </div>
                )}
                {userMockObject && 
                    <>
                        <DashboardNavbar user={userMock} superRole={isAdmin} serialNr={serialNr}/>
                        <div className='Dashboard-Body-Container' style={darkMode ? {backgroundColor: '#121212', color: 'white'} : {backgroundColor: 'rgb(217, 217, 217)', color: 'black'}}>
                            <DashboardSideNavbar changeTab={changeTab} />
                            {activeTab == "Dashboard" && <DashboardOverview serialNumber={serialNr}/>}
                            {activeTab == "Calendar" && <DashboardCalendar serialNr={serialNr} setChangesMadewithoutSaving={setChangesMadewithoutSaving} changesMade={changesMade} setChangesMade={setChangesMade} accountType={accountType} ToggleLoading={setLoading}/>}
                            {activeTab == "Settings" && <DashbordSettings serialNr={serialNr}/>}
                        </div>
                    </>
                }
            </div>
        </>
    )


}


export default Dashboard;




