import './newsPage.css';
import React from 'react';
import { useNews, formatDate } from '../../../Utils/useNews';
import defaultLogo from '../../../Images/firn-hor.png';

const NewsPage = () => {
    const MAX_DESCRIPTION_LENGTH = 150;
    const { newsItems, loading, error } = useNews();

    if (loading) {
        return <p>Nieuwsberichten laden...</p>;
    }

    if (error) {
        return <p className="error-message">{error}</p>;
    }

    return (
        <div className="newsPage__body">
            {newsItems.map((item, index) => (
                <a
                    key={item.id || index}
                    href={`/Nieuws/${item.id}`}
                    className="newsPage__item-link"
                >
                    <div className="newsPage__item">
                        <img
                            src={item.mainImage}
                            alt={`News Image ${item.id}`}
                            className="newsPage__image"
                            onError={(e) => {
                                e.target.src = defaultLogo;
                                e.target.onerror = null;
                            }}
                        />
                        <div className="newsPage__details">
                            <p className="newsPage__date">{formatDate(item.createdAt)}</p>
                            <p className="newsPage__shortDescription">{item.shortDescription}</p>
                            <p className="newsPage__longDescription">
                                {item.longDescription
                                    .replace(/<br\s*\/?>/g, '\n')
                                    .slice(0, MAX_DESCRIPTION_LENGTH)
                                    .concat(item.longDescription.length > MAX_DESCRIPTION_LENGTH ? '...' : '')}
                            </p>
                            <p className="newsPage__hashtags">{item.hashtags}</p>
                        </div>
                    </div>
                </a>
            ))}
        </div>
    );
};

export default NewsPage;
