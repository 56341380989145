import { Label, ComposedChart, CartesianGrid , Legend, CartesianAxis, ResponsiveContainer, XAxis, YAxis, Bar, Tooltip } from 'recharts'
import './graphMaker.css'
import { useEffect, useState } from 'react';
import { addHours, format, startOfDay } from 'date-fns';

const GraphMaker = ({handleRetry, showRetryMessage, data, chartObjects, handleSelectedItemRemove, date}) => {

    const [isPercentageNeeded, setIsPercentageNeeded] = useState(false);

    const generateHourlyTicks = () => {
        const start = startOfDay(date);
        return Array.from({ length: 24 }, (_, i) => addHours(start, i).getTime());
    };
    
    const [hourlyTicks, setHourlyTicks] = useState(generateHourlyTicks())

    const handleLegendClick = (event) => {
        handleSelectedItemRemove(event.dataKey);
        //look at the datakey
    }

    useEffect(() => {
        setHourlyTicks(generateHourlyTicks())
    }, [date])    
    
    const CustomTooltip = ({ active, payload, label }) => {

        if (active && payload && payload.length) {
          const time = label ? new Date(label).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false}) : "N/A";
          const values = payload.map((entry) => (
            <div key={entry.dataKey} style={{ color: entry.color }}>
              {entry.name}: {entry.unit != 'kWh' ? Math.ceil(entry.value) + entry.unit : (entry.value / 1000).toFixed(2) + entry.unit}
            </div>
          ));
      
          return (
            <div style={{
              backgroundColor: 'rgba(255, 255, 255, 0.9)',
              padding: '10px',
              border: '1px solid #ddd',
              borderRadius: '10px',
            }}>
              <div><strong>Time:</strong> {time}</div>
              {values}
            </div>
          );
        }
      
        return null;
    };

    if (showRetryMessage) {
      return (
        <div className='dashboard__savings__container' style={{justifyContent: 'center', alignItems: 'center'}}>
          <div>Error loading data. Please try again later.</div>
          <button onClick={handleRetry} className='dashboard__savings__retry__button'>Retry</button>
        </div>
      );
    }

    return (
        <ResponsiveContainer style={{fontSize: "1rem", marginTop: 20}} width={"100%"} height="85%">
            <ComposedChart data={data} key={date.toISOString()}>
                <XAxis interval={1} dataKey="timestamp" type='number' domain={['dataMin - 1800000', 'dataMax + 1800000']} ticks={hourlyTicks} tickFormatter={(tick) => format(new Date(tick), "HH:mm")}/>
                <YAxis tickFormatter={(tick) => (tick / 1000).toFixed(1)} unit={'kWh'} width={100}/>
                <YAxis yAxisId={'Percentage'} unit={'%'} orientation='right' domain={[0, 100]} hide={isPercentageNeeded}/>
                <CartesianGrid style={{opacity: 0.5}} vertical={false}/>
                
                <Tooltip content={<CustomTooltip />} />

                {chartObjects.map((object, index) => {
                    if (object.object.props.yAxisId == 'Percentage') {
                        () => setIsPercentageNeeded(true);
                    }
                    return (
                        object.object
                    )
                }  
                )}
                <Legend wrapperStyle={{ color: '#555', fontSize: '0.9rem', fontWeight: 'bold', cursor: 'pointer' }} onClick={handleLegendClick} />
            </ComposedChart>
        </ResponsiveContainer>
    );

}

export default GraphMaker