import React, { useState } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import './homePageNavbar.css';
import kleinLogo from '../../../Images/embleem.png';
import logoMedium from '../../../Images/firn-hor.png';
import logo from '../../../Images/firn-ver.png';
import pdf1 from '../../../pdf/FIRN energy - Algemene voorwaarden.pdf'
import pdf2 from '../../../pdf/FIRN energy - Algemene voorwaarden inzake aankoop goederen en hardware.pdf'
import { trackClickToSimulationPage } from '../../../Utils/simulationHelper';

const HomePageNavBar = ({ isHomePage }) => {
    const { loginWithRedirect } = useAuth0();
    const [menuOpen, setMenuOpen] = useState(false);

    const handleMenuToggle = () => {
        setMenuOpen(!menuOpen);
        document.body.style.overflow = menuOpen ? 'auto' : 'hidden';
    };

    return (
        <nav className={`homePage__navbar ${isHomePage ? 'homePage__navbar--homepage' : ''}`}>
            <div className={`homePage__navbar__left ${isHomePage ? "homePage__navbar__left--homepage" : ""}`}>
                <div className={isHomePage ? "homePage__navbar__logo" : "homePage__navbar__logo--medium"}>
                    <a href="/">
                        <img src={isHomePage ? kleinLogo : logoMedium} alt="FIRN energy Logo" />
                    </a>
                </div>
                <ul className="homePage__navbar__menu left-menu">
                    <li><a href="/Oplossingen">ONZE <b>OPLOSSINGEN</b></a></li>
                    <li className="homePage__navbar__controller">
                        <a href="/Controller">ONZE <b>CONTROLLER</b></a>
                    </li>
                </ul>
            </div>
            <div className="homePage__navbar__middle">
                {isHomePage && (
                    <img src={logo} alt="FIRN energy Logo" />
                )}
            </div>
            <div className={`homePage__navbar__right ${isHomePage ? "homePage__navbar__right--homepage" : ""}`}>
                <ul className="homePage__navbar__menu right-menu">
                    <li><a
                        href="/SimulatieAanvragen"
                        onClick={() => { trackClickToSimulationPage() }}
                    >SIMULATIE</a></li>
                    <li><a href="/Nieuws">NIEUWS</a></li>
                    <li><a href="/Contact">CONTACT</a></li>
                </ul>
                <div className="homePage__navbar__dashboard">
                    <button href="#" className="homePage__dashboard__button"
                        onClick={() => loginWithRedirect({
                            appState: {
                                returnTo: "/HomePage"
                            }
                        })}>Dashboard</button>
                </div>
            </div>

            <div className="homePage__navbar__hamburger" onClick={handleMenuToggle}>
                <div className={`homePage__navbar__hamburger-lines ${menuOpen ? 'open' : ''}`}>
                    <span className="line line1"></span>
                    <span className="line line2"></span>
                    <span className="line line3"></span>
                </div>
            </div>

            <div className={`homePage__navbar__hamburger-menu ${menuOpen ? 'active' : ''}`}>
                <ul className={`homePage__navbar__menu-items`}>
                    <li><a href="/Oplossingen">Onze Oplossingen</a></li>
                    <li><a href="/Controller">Onze Controller</a></li>
                    <li><a href="/SimulatieAanvragen" onClick={() => { trackClickToSimulationPage() }}>Simulatie</a></li>
                    <li><a href="/Nieuws">Nieuws</a></li>
                    <li><a href="/Contact">Contact</a></li>
                    <li><a href="#" onClick={() => loginWithRedirect()} className="homePage__dashboard__button">Dashboard</a></li>
                </ul>

                <div className="homePage__navbar-bottom">
                    <a href={pdf1}>Algemene voorwaarden</a><span> | </span>
                    <a href={pdf2}>Aankoop goederen & hardware</a>
                </div>
            </div>
        </nav>
    );
};

export default HomePageNavBar;
