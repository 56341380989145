import User from "../../../api/user";
import formatDataForChart from "./formatChartData";


const useDayAheadData = async(globalDate, setRange) => {

    const userApi = User();

    try {
        const tommorow = new Date(globalDate);
        tommorow.setDate(globalDate.getDate() + 1);

        const localDate = (date) => {
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const day = String(date.getDate()).padStart(2, '0');
            return `${year}-${month}-${day}`
        }

        const dayahead = await userApi.getDayAheadPrice(
            localDate(globalDate), 
            localDate(tommorow)
        );

        const startOfCurrentDay = new Date(globalDate.setHours(0, 0, 0, 0));
        const endOfCurrentDay = new Date(startOfCurrentDay);
        endOfCurrentDay.setDate(startOfCurrentDay.getDate() + 1);
        
        let maxValue = -Infinity;
        let minValue = +Infinity;

        const newDayAhead = dayahead.time.map((time, i) => {
            const dateObject = new Date(time);
            const value = dayahead.values[i];

            maxValue = Math.max(maxValue, value);
            minValue = Math.min(minValue, value);

            return {date: dateObject, dayahead: value};
        })
        
        setRange({max: Math.ceil(maxValue / 5) * 5, min: Math.floor(minValue / 5) * 5});

        return formatDataForChart(newDayAhead)
    } catch (error) {
        console.error("Error fetching day-ahead data", error)
        return null
    }
}

export default useDayAheadData;