import React, { useState, useEffect } from 'react';
import './addNews.css';

const apiUrl = process.env.REACT_APP_API_BASE_URL;

const AddNews = ({ existingNews, onSuccess }) => {
    const [shortDescription, setShortDescription] = useState('');
    const [longDescription, setLongDescription] = useState('');
    const [mainImageMethod, setMainImageMethod] = useState('url');
    const [mainImage, setMainImage] = useState(null);
    const [mainImageURL, setMainImageURL] = useState('');
    const [additionalImages, setAdditionalImages] = useState([
        { method: 'url', file: null, url: '' },
        { method: 'url', file: null, url: '' },
        { method: 'url', file: null, url: '' },
    ]);
    const [hashtags, setHashtags] = useState(['', '', '', '']);
    const [error, setError] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);

    useEffect(() => {
        if (existingNews) {
            setShortDescription(existingNews.shortDescription || '');
            setLongDescription(
                (existingNews.longDescription || '')
                    .replace(/(<br\s*\/?>\s*){2,}/g, '\n')
                    .replace(/<br\s*\/?>/g, '')
            );
            setMainImageMethod(existingNews.mainImageMethod || 'url');
            setMainImage(null);
            setMainImageURL(existingNews.mainImage || '');
            setAdditionalImages(
                Array(3)
                    .fill({ method: 'url', file: null, url: '' })
                    .map((defaultImage, index) => ({
                        ...defaultImage,
                        ...(existingNews.additionalImages && existingNews.additionalImages[index]
                            ? { url: existingNews.additionalImages[index], method: 'url' }
                            : {}),
                    }))
            );
            setHashtags([
                ...existingNews.hashtags.map((hashtag) => hashtag.replace(/^#/, '').trim()),
                '',
            ].slice(0, 4));
        }
    }, [existingNews]);

    const handleMainImageChange = (e) => {
        setMainImage(e.target.files[0]);
    };

    const handleMainImageURLChange = (e) => {
        setMainImageURL(e.target.value);
    };

    const handleAdditionalImageChange = (index, method, value) => {
        const updatedImages = [...additionalImages];
        updatedImages[index] = {
            method,
            file: method === 'upload' ? value : null,
            url: method === 'url' ? value : '',
        };
        setAdditionalImages(updatedImages);
    };

    const handleHashtagChange = (index, value) => {
        const newHashtags = [...hashtags];
        newHashtags[index] = value;
        if (value && newHashtags.length < 4 && !newHashtags.includes('')) {
            newHashtags.push('');
        }
        setHashtags(newHashtags);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);
        setError('');

        if (!shortDescription || !longDescription || (mainImageMethod === 'upload' && !mainImage) || (mainImageMethod === 'url' && !mainImageURL)) {
            setError('Alle verplichte velden moeten ingevuld worden.');
            setIsSubmitting(false);
            return;
        }

        if (shortDescription.length > 100) {
            setError('Korte beschrijving mag niet langer zijn dan 100 tekens.');
            setIsSubmitting(false);
            return;
        }

        const formattedLongDescription = longDescription.replace(/\n/g, '\\n\\n');

        const formData = new FormData();
        formData.append('shortDescription', shortDescription);
        formData.append('longDescription', formattedLongDescription);
        if (mainImageMethod === 'url') {
            formData.append('mainImageURL', mainImageURL);
        } else {
            formData.append('mainImage', mainImage);
        }
        additionalImages.forEach((image, index) => {
            if (image.method === 'upload' && image.file) {
                formData.append('additionalImages', image.file);
            } else if (image.method === 'url' && image.url) {
                formData.append(`additionalImagesURL[${index}]`, image.url);
            }
        });
        formData.append('hashtags', hashtags.filter((tag) => tag).join(','));

        const endpoint = existingNews ? `${apiUrl}/news/${existingNews.id}` : `${apiUrl}/news`;
        const method = existingNews ? 'PUT' : 'POST';

        try {
            const response = await fetch(endpoint, {
                method,
                body: formData,
            });

            if (response.ok) {
                const result = await response.json();
                console.log(existingNews ? 'Nieuwsbericht succesvol bijgewerkt:' : 'Nieuwsbericht succesvol toegevoegd:', result);

                setShortDescription('');
                setLongDescription('');
                setMainImage(null);
                setMainImageURL('');
                setAdditionalImages([
                    { method: 'url', file: null, url: '' },
                    { method: 'url', file: null, url: '' },
                    { method: 'url', file: null, url: '' },
                ]);
                setHashtags(['', '', '', '']);
                onSuccess();
            } else {
                const errorData = await response.json();
                setError(errorData.error || 'Er is een fout opgetreden.');
            }
        } catch (error) {
            console.error('Fout bij het versturen van het formulier:', error);
            setError('Kan geen verbinding maken met de server.');
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <div className="news-form-container">
            <form onSubmit={handleSubmit} encType="multipart/form-data">
                <div>
                    <label htmlFor="shortDescription">Titel / Korte beschrijving (max 100 tekens):</label>
                    <input
                        type="text"
                        id="shortDescription"
                        value={shortDescription}
                        onChange={(e) => setShortDescription(e.target.value)}
                        maxLength="100"
                        required
                    />
                </div>

                <div>
                    <label htmlFor="longDescription">Lange beschrijving:</label>
                    <textarea
                        id="longDescription"
                        value={longDescription}
                        onChange={(e) => setLongDescription(e.target.value)}
                        required
                    />
                </div>

                <div>
                    <label>Verplichte afbeelding:</label>
                    <p className='news-form__onderschrift'>Afbeelding toevoegen adhv upload staat nog niet op punt, probeer een URL te gebruiken.</p>
                    <div className="news-form-image-input-container">
                        <div className="news-form-image-input-group">
                            <select
                                value={mainImageMethod}
                                onChange={(e) => setMainImageMethod(e.target.value)}
                            >
                                <option value="upload">Upload</option>
                                <option value="url">URL</option>
                            </select>
                            {mainImageMethod === 'upload' ? (
                                <input
                                    type="file"
                                    onChange={handleMainImageChange}
                                    accept="image/*"
                                    required
                                />
                            ) : (
                                <input
                                    type="url"
                                    value={mainImageURL}
                                    onChange={handleMainImageURLChange}
                                    placeholder="Geef een URL op"
                                    required
                                />
                            )}
                        </div>
                    </div>
                </div>

                <div>
                    <label>Extra afbeeldingen (optioneel en max 3):</label>
                    {additionalImages.map((image, index) => (
                        <div key={index} className="news-form-image-input-container">
                            <div className="news-form-image-input-group">
                                <select
                                    value={image.method}
                                    onChange={(e) =>
                                        handleAdditionalImageChange(
                                            index,
                                            e.target.value,
                                            image.method === 'url' ? image.url : null
                                        )
                                    }
                                >
                                    <option value="upload">Upload</option>
                                    <option value="url">URL</option>
                                </select>
                                {image.method === 'upload' ? (
                                    <input
                                        type="file"
                                        onChange={(e) =>
                                            handleAdditionalImageChange(index, 'upload', e.target.files[0])
                                        }
                                        accept="image/*"
                                    />
                                ) : (
                                    <input
                                        type="url"
                                        value={image.url}
                                        onChange={(e) =>
                                            handleAdditionalImageChange(index, 'url', e.target.value)
                                        }
                                        placeholder="Geef een URL op"
                                    />
                                )}
                            </div>
                        </div>
                    ))}
                </div>

                <div>
                    <label>Hashtags (max 4):</label>
                    {hashtags.map((hashtag, index) => (
                        (index === 0 || hashtags[index - 1]) && (
                            <input
                                key={index}
                                type="text"
                                value={hashtag}
                                onChange={(e) => handleHashtagChange(index, e.target.value)}
                                placeholder={`Hashtag ${index + 1}`}
                            />
                        )
                    ))}
                </div>

                <button
                    type="submit"
                    disabled={isSubmitting}
                >
                    {isSubmitting
                        ? 'Bezig met verzenden...'
                        : existingNews ? 'Nieuwsbericht bijwerken' : 'Nieuwsbericht toevoegen'}
                </button>
                {error && <p className="error-message">{error}</p>}
            </form>
        </div>
    );
};

export default AddNews;
