import React from 'react';
import { useNews } from '../../../Utils/useNews';
import './homePageNewsAlert.css';
import defaultLogo from '../../../Images/firn-hor.png';

const HomePageNewsAlert = ({ scrollToNews }) => {
    const { newsItems, loading, error } = useNews();

    if (loading || error || newsItems.length === 0) {
        return null;
    }

    const latestNewsItem = newsItems[0];

    return (
        <div className="homePage__news-alert">
            <div className="homePage__news-alert__hexagon homePage__news-alert__hexagon-link">
                <p onClick={scrollToNews} className='homePage__news-alert__link'>Lees Meer Nieuws Hier</p>
            </div>
            <div className="homePage__news-alert__hexagon homePage__news-alert__hexagon-image">
                <img src={latestNewsItem.mainImage || defaultLogo}
                    alt="Latest News"
                    onError={(e) => {
                        e.target.src = defaultLogo;
                        e.target.onerror = null;
                    }}
                />
            </div>
            <div className="homePage__news-alert__hexagon homePage__news-alert__hexagon-text">
                <p>{latestNewsItem.shortDescription}</p>
            </div>
        </div>
    );
};

export default HomePageNewsAlert;
