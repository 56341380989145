import React, { useState, useEffect } from 'react';
import { useNews, formatDate } from '../../../Utils/useNews';
import defaultLogo from '../../../Images/firn-hor.png';
import '@fortawesome/fontawesome-free/css/all.min.css';
import './newsList.css';

const NewsList = ({ onEditNews }) => {
    const { newsItems: initialNewsItems, loading, error } = useNews();
    const [newsItems, setNewsItems] = useState([]);

    useEffect(() => {
        setNewsItems(initialNewsItems);
    }, [initialNewsItems]);

    const handleDelete = async (id) => {
        if (window.confirm('Weet je zeker dat je dit nieuwsbericht wilt verwijderen?')) {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/news/${id}`, {
                    method: 'DELETE',
                });

                if (!response.ok) {
                    throw new Error('Fout bij het verwijderen van het nieuwsbericht.');
                }

                setNewsItems((prevNewsItems) =>
                    prevNewsItems.filter((item) => item.id !== id)
                );
            } catch (err) {
                alert('Er is een fout opgetreden bij het verwijderen van het nieuwsbericht.');
            }
        }
    };

    const handleEdit = (news) => {
        onEditNews(news)
    };

    if (loading) {
        return <p>Nieuwsberichten laden...</p>;
    }

    if (error) {
        return <p className="error-message">{error}</p>;
    }

    return (
        <div className="newsList__body">
            {newsItems.map((item) => (
                <div key={item.id} className="newsList__item">
                    <img
                        src={item.mainImage}
                        alt={`News Image ${item.id}`}
                        className="newsList__image"
                        onError={(e) => {
                            e.target.src = defaultLogo;
                            e.target.onerror = null;
                        }}
                    />
                    <div className="newsList__details">
                        <p className="newsList__date">{formatDate(item.createdAt)}</p>
                        <p className="newsList__shortDescription">{item.shortDescription}</p>
                        <p
                            className="newsList__longDescription"
                            dangerouslySetInnerHTML={{ __html: item.longDescription }}
                        />
                        <p className="newsList__hashtags">{item.hashtags}</p>
                    </div>
                    <div className="newsList__actions">
                        <button onClick={() => handleEdit(item)} className="newsList__icon-button">
                            <i className="fas fa-pen-to-square"></i>
                        </button>
                        <button
                            onClick={() => handleDelete(item.id)}
                            className="newsList__icon-button"
                        >
                            <i className="fas fa-trash-can"></i>
                        </button>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default NewsList;
