import { useContext } from "react";
import { DarkModeContext } from "../../../context/DarkModeContext";


const DashboardBlock = ({ children, addClassname }) => {

    const {darkMode} = useContext(DarkModeContext);

    const style = {
        backgroundColor: darkMode ? '#232323' : '',
        border: darkMode ? '#2E2E2E solid 2px' : '2px solid #D9D9D9',
        boxShadow: darkMode ? '' : '0 0 20px rgba(0, 0, 0, 0.1)',
    }

    return (   
        <div className={"Dashboard-Body-Overview-Row-Element-Container " + addClassname} style={style}>
            {children}
        </div>
    );
}

export default DashboardBlock;